<template>
  <v-main>
    <v-container>
      <Async class="mx-auto" :loading="isFetching">
        <div v-if="isNotValid" class="fill-all">
          <v-card class="not_found_card flex-center" width="500" height="500">
            <v-card-text class="text-center">
              <v-icon color="primary" size="96">
                mdi-emoticon-cry-outline
              </v-icon>
              <SizeBox height="12" />
              <p class="text-h4 Bold">{{ $t('CANT_FIND_ORDER') }}</p>
            </v-card-text>
          </v-card>
        </div>
        <v-card v-else>
          <v-card-text>
            <v-alert
              border="left"
              elevation="3"
              color="primary"
              class="text-h5 Bold"
              dark
            >
              {{ $t('EXPIRED_AT') }} : {{ payingState.expired_at | formatTime }}
            </v-alert>

            <SizeBox height="24" />

            <v-text-field
              v-for="(item, index) in fields"
              :key="`field: ${index}`"
              rounded
              :label="item.label"
              :value="getValue(item)"
              readonly
              outlined
              class="remove-append-margin"
            >
              <template #append v-if="!item.noCopy">
                <v-btn @click="doCopy(getValue(item))">
                  {{ $t('COPY') }}
                </v-btn>
              </template>
            </v-text-field>

            <p class="title">{{ $t('PAYING_PROCESS.HINT_TITLE') }}</p>
            <ul>
              <li>
                {{ $t('PAYING_PROCESS.HINT_CONTENT.1') }}
              </li>
              <li>
                {{ $t('PAYING_PROCESS.HINT_CONTENT.2') }}
              </li>
              <li>
                {{ $t('PAYING_PROCESS.HINT_CONTENT.3') }}
              </li>
              <li>
                {{ $t('PAYING_PROCESS.HINT_CONTENT.4') }}
              </li>
            </ul>
          </v-card-text>
        </v-card>
      </Async>
    </v-container>

    <v-btn
      fab
      small
      fixed
      bottom
      right
      @click="
        $root.$i18n.locale = $root.$i18n.locale === 'zh-CN' ? 'zh-TW' : 'zh-CN'
      "
    >
      <v-icon>mdi-translate</v-icon>
    </v-btn>
  </v-main>
</template>

<script>
import { getCollectOrderForPay } from '@/api/collectOrders';

export default {
  data() {
    return {
      isUnavailabel: false,
      isFetching: true,
      isNotValid: false,
      payingState: {
        // 商戶單號
        merchant_order_no: '',
        // 平台單號
        platform_order_no: '',
        // 訂單金額
        verify_amt: '',
        // 建立時間
        created_at: '',
        // 付款人姓名
        payer_name: '',
        // 過期時間
        expired_at: '',
        card: {
          // 收款人姓名
          bank_holder: '',
          // 收款銀行名稱
          bank_name: '',
          // 銀行分行
          bank_branch: '',
          // 收款卡號
          bank_card_no: ''
        }
      },
      fields: [
        {
          name: 'payingState.verify_amt',
          label: this.$t('VERIFY_AMT')
        },
        {
          name: 'payingState.card.bank_holder',
          label: this.$t('CARD.BANK_HOLDER')
        },
        {
          name: 'payingState.card.bank_name',
          label: this.$t('CARD.BANK_NAME')
        },
        {
          name: 'payingState.card.bank_branch',
          label: this.$t('CARD.BANK_BRANCH')
        },
        {
          name: 'payingState.card.bank_card_no',
          label: this.$t('CARD.BANK_CARD_NO')
        },
        {
          name: 'payingState.merchant_order_no',
          label: this.$t('MERCHANT_ORDER_NO'),
          noCopy: true
        },
        {
          name: 'payingState.platform_order_no',
          label: this.$t('PLATFORM_ORDER_NO'),
          noCopy: true
        },
        {
          name: 'payingState.created_at',
          label: this.$t('CREATED_AT'),
          filter: 'formatTime',
          noCopy: true
        },
        {
          name: 'payingState.payer_name',
          label: this.$t('PAYER_NAME'),
          noCopy: true
        }
      ]
    };
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      const { merchant_order_no, platform_order_no } = this.$route.query;
      if (!merchant_order_no || !platform_order_no) {
        this.isNotValid = true;
        this.isFetching = false;
        return;
      }

      const payload = {
        merchant_order_no,
        platform_order_no
      };

      const Res = await getCollectOrderForPay(payload);

      if (Res.error) {
        this.isNotValid = true;
      } else {
        this.$set(this, 'payingState', Res);
      }

      this.isFetching = false;
    },

    getValue(item) {
      const name = item.name;
      const value = name.split('.').reduce((p, c) => {
        return p[c];
      }, this);

      if (item.filter) {
        return this.$root.$options.filters[item.filter](value);
      }

      return value;
    },

    async doCopy(v) {
      const { error } = await this.$copy(v);

      if (!error) {
        this.$toast(this.$t('COPIED'));
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.remove-append-margin {
  &::v-deep .v-input__append-inner {
    align-self: stretch;
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

.not_found_card {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>